import React from "react";

import logo from "../img/onda-logo.png";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter section">
        <div className="content">
          <div className="container">
            <div className="columns">
              <div className="column is-8 has-text-centered-mobile">
                <h3 className="is-uppercase is-size-6">Surf Hotel</h3>
                <img src={logo} alt="onda" style={{ maxHeight: "1rem" }} />
              </div>

              <div className="column is-4 social has-text-centered-mobile">
                <h3 className="is-uppercase is-size-6">Social Links</h3>
                <a title="facebook" href="https://www.facebook.com/ondasurfhotel/">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "2em", height: "2em" }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com/ondasurfhotel?igshid=s1by8mx1vim5">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "2em", height: "2em" }}
                  />
                </a>
              </div>
            </div>
            <div style={{ padding: '2rem 2rem'}}>
              <p className="subtitle is-size-7 has-text-centered">© 2019 ONDA HOTELS. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
